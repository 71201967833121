<script setup lang="ts">
defineOptions({
  inheritAttrs: false
});

const {
  loading = false,
  elementsLength,
  direction = "down"
} = defineProps<{
  loading?: boolean | Ref<boolean> | undefined;
  direction?: "up" | "down" | "both";
  elementsLength: number;
}>();

const emit = defineEmits<{
  (e: "loadMore", direction: "up" | "down" | null): void;
}>();

const triggerUp = ref(null);
const triggerDown = ref(null);
const isVisible = ref(false);
const lastDirection = ref<"up" | "down" | null>(null);

useIntersectionObserver(triggerUp, ([{ isIntersecting }]) => {
  isVisible.value = isIntersecting;
  if (isIntersecting && !loading) {
    lastDirection.value = "up";
    emit("loadMore", "up");
  }
});
useIntersectionObserver(triggerDown, ([{ isIntersecting }]) => {
  isVisible.value = isIntersecting;
  if (isIntersecting && !loading) {
    lastDirection.value = "down";
    emit("loadMore", "down");
  }
});

watch(
  () => elementsLength,
  () => {
    if (isVisible.value) {
      emit("loadMore", direction === "both" ? lastDirection.value : direction);
    }
  }
);
</script>

<template>
  <div v-if="direction === 'up' || direction === 'both'" ref="triggerUp" class="min-h-px" />
  <slot />
  <div v-if="direction === 'down' || direction === 'both'" ref="triggerDown" class="min-h-px" />
</template>
